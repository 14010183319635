.toolsContainer
  display: flex
  flex-flow: row wrap
  margin-bottom: 8px
  .postpone
    flex: 1 auto
    display: flex
    flex-flow: row nowrap
    justify-content: space-evenly
    .buttons
      :first-child
        margin-bottom: 4px
      display: flex
      flex-flow: column nowrap
      justify-content: space-evenly
  .origins
    flex: 1 auto
    margin-left: 8px
    display: flex
    flex-flow: row wrap
    .content
      display: flex
      flex-flow: column nowrap
